export default {
  addProductToBasket({ commit }, product) {
    commit("ADD_PRODUCT_TO_BASKET", product);
  },
  addProductGroup({ commit }, productGroup) {
    commit("ADD_PRODUCT_GROUP", productGroup);
  },
  initBasket({ commit }) {
    commit("INIT_BASKET");
  },
  removeItem({ commit }, item) {
    commit("REMOVE_ITEM", item);
  },
  changeQuantity({ commit }, item) {
    commit("CHANGE_QUANTITY", item);
  },
  removeProductGroup({ commit }, productGroup) {
    commit("REMOVE_PRODUCT_GROUP", productGroup);
  },
  clearBasket({ commit }) {
    commit("CLEAR_BASKET");
  },
  setSizeProblem({ commit }, sizeProblemItem) {
    commit("SET_SIZE_PROBLEM", sizeProblemItem);
  },
  ClearItemSizeSuitability({ commit }) {
    commit("CLEAR_ITEM_SIZE_SUITABILITY");
  }
};
