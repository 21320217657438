export default {
  CHANGE_SELECTED_WEIGHT_MULTIPIER(state, multipier) {
    state.selectedWeightMultipier = multipier;
  },
  ADD_PRODUCT_TO_BASKET(state, product) {
    if (state.basket.length > 0) {
      let index = state.basket.findIndex(e => e.id == product.id);
      if (index == -1) {
        state.basket.push(product);
      } else {
        state.basket[index].quantity =
          parseInt(state.basket[index].quantity) + parseInt(product.quantity);
      }
    } else {
      state.basket.push(product);
    }
    localStorage.removeItem("noktaBasket");
    localStorage.setItem("noktaBasket", JSON.stringify(state.basket));
  },
  ADD_PRODUCT_GROUP(state, productGroup) {
    state.productGroups.push(productGroup);
    localStorage.removeItem("noktaProductGroups");
    localStorage.setItem(
      "noktaProductGroups",
      JSON.stringify(state.productGroups)
    );
  },
  INIT_BASKET(state) {
    if (localStorage.getItem("noktaBasket")) {
      state.basket = JSON.parse(localStorage.getItem("noktaBasket"));
    }
    if (localStorage.getItem("noktaProductGroups")) {
      state.productGroups = JSON.parse(
        localStorage.getItem("noktaProductGroups")
      );
    }
  },
  REMOVE_ITEM(state, item) {
    let index = state.basket.findIndex(e => e.id == item.id);
    if (index != -1) {
      state.basket.splice(index, 1);
    }
    localStorage.removeItem("noktaBasket");
    localStorage.setItem("noktaBasket", JSON.stringify(state.basket));
  },
  CHANGE_QUANTITY(state, item) {
    let tmpState = JSON.parse(JSON.stringify(state.basket));
    let index = tmpState.findIndex(e => e.id == item.id);
    if (index != -1) {
      tmpState[index].quantity = parseInt(item.quantity);
    }
    state.basket = tmpState;
    localStorage.removeItem("noktaBasket");
    localStorage.setItem("noktaBasket", JSON.stringify(state.basket));
  },
  REMOVE_PRODUCT_GROUP(state, productGroup) {
    state.basket = state.basket.filter(e => e.product_group.id != productGroup);
    localStorage.removeItem("noktaBasket");
    localStorage.setItem("noktaBasket", JSON.stringify(state.basket));
  },

  CLEAR_BASKET(state) {
    state.basket = [];
    state.productGroups = [];
    localStorage.removeItem("noktaBasket");
    localStorage.removeItem("noktaProductGroups");
  },
  SET_SIZE_PROBLEM(state, sizeProblemItem) {
    let index = state.basket.findIndex(e => e.title == sizeProblemItem);
    state.basket[index].sizeSuitability = false;
  },
  CLEAR_ITEM_SIZE_SUITABILITY(state) {
    state.basket.forEach(e => {
      e.sizeSuitability = true;
    });
  }
};
