import state from "./moduleDentalState.js";
import mutations from "./moduleDentalMutations.js";
import actions from "./moduleDentalActions.js";
import getters from "./moduleDentalGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
