export default {
  FIRST_ADDED_SHOP_LIST(state, list) {
    state.shopItems = list;
  },
  ADD_ITEM_IN_SHOP(state, item) {
    state.shopItems.push(Object.assign({}, item));
    localStorage.removeItem("shop");
    localStorage.setItem("shop", JSON.stringify(state.shopItems));
  },
  ADD_ITEM_IN_SHOP_VISIBLE(state, item) {
    state.shopItemsVisible.push(Object.assign({}, item));
  },
  REMOVE_ITEM_IN_SHOP(state, item) {
    const index = state.shopItems.findIndex(i => i.id == item.id);
    state.shopItems.splice(index, 1);
    localStorage.removeItem("shop");
    localStorage.setItem("shop", JSON.stringify(state.shopItems));
  },
  CLEAR_ITEM_SHOP(state) {
    state.shopItems.splice(0, JSON.stringify(state.shopItems).length);
    state.shopItemsVisible.splice(
      0,
      JSON.stringify(state.shopItemsVisible).length
    );
    localStorage.removeItem("shop");
    localStorage.removeItem("note");
  },
  CLEAR_ITEM_SHOP_INVISIBLE(state) {
    state.shopItemsVisible.splice(
      0,
      JSON.stringify(state.shopItemsVisible).length
    );
  },
  UPDATE_ITEM_QUANTITY(state, payload) {
    const index = state.shopItems.findIndex(i => i.id == payload.id);

    if (index != -1) {
      state.shopItems[index].quantity = payload.quantity;
      state.shopItems[index].selectedSize = [0, 0, 0, 0, 0];
    }
    localStorage.removeItem("shop");
    localStorage.setItem("shop", JSON.stringify(state.shopItems));
  },
  UPDATE_ITEM_QUANTITY_SAME_ITEM(state, payload) {
    state.shopItems.forEach(element => {
      if (element.id == payload.id) {
        element.selectedSize = [0, 0, 0, 0, 0];
        element.quantity =
          parseInt(element.quantity) + parseInt(payload.quantity);
      }
    });
    localStorage.removeItem("shop");
    localStorage.setItem("shop", JSON.stringify(state.shopItems));
  },
  NEW_UPDATE_ITEM_QUANTITY(state, payload) {
    state.shopItems.forEach(element => {
      if (element.id == payload.id) {
        element.selectedSize[payload.sizeIndex] = parseInt(payload.quantity);
        element.quantity =
          element.selectedSize[0] +
          element.selectedSize[1] +
          element.selectedSize[2] +
          element.selectedSize[3] +
          element.selectedSize[4];
      }
    });
    localStorage.removeItem("shop");
    localStorage.setItem("shop", JSON.stringify(state.shopItems));
  }
};
