const getters = {
  getWorkToDo: state => {
    return state.workToDo;
  },
  getImplant: state => {
    return state.implant;
  },
  getMateriel: state => {
    return state.materiel;
  },
  getDentalWork: state => {
    return state.dentalWork;
  },
  getDentalOrder: state => {
    return state.order;
  },
  getDentalWorks: state => {
    return state.works;
  },
  getDentalOrderItems: state => {
    return state.order.order_items;
  },
  getOrderFiles: state => {
    return state.order.order_files;
  },
  getTeethRelations: state => {
    return state.order.teeth_relation;
  },
  getTotalTreatmentCredit: state => {
    var credit = 0;

    state.dentalWork.forEach(element => {
      credit += element.work.credit;
    });

    return credit
  },
  getSelectedWorks: state => {
    var works = [];
    if (state.dentalWork.length > 0) {
      works.push(state.dentalWork[0].work);
      state.dentalWork.forEach(element => {
        const index = works.findIndex(i => i.color == element.work.color);
        if (index == -1) {
          works.push(element.work);
        }
      });
    }
    return works;
  },
  getDifferentWorks: state => {
    var differentWorks = [];

    if (state.dentalWork.length > 0) {
      differentWorks.push({
        name: state.dentalWork[0].work.name,
        credit: state.dentalWork[0].work.credit,
        color: state.dentalWork[0].work.color,
        unit: 1
      });
    }

    for (let index = 1; index < state.dentalWork.length; index++) {
      const indexNewOrder = differentWorks.findIndex(
        i => i.name == state.dentalWork[index].work.name && i.credit != state.dentalWork[index].work.credit
      );

      if (indexNewOrder != -1) {
        differentWorks[indexNewOrder].credit = state.dentalWork[index].work.credit
      }

      const indexDif = differentWorks.findIndex(
        i => i.name == state.dentalWork[index].work.name
      );

      if (indexDif != -1) {
        differentWorks[indexDif].unit++;
      } else {
        differentWorks.push({
          name: state.dentalWork[index].work.name,
          credit: state.dentalWork[index].work.credit,
          color: state.dentalWork[index].work.color,
          unit: 1
        });
      }
    }
    return differentWorks;
  },
  get3DPrintsTeeth: state => {
    var teeth3DPrints = [];

    state.order.order_items.forEach(element => {
      if (element.material == 1) {
        teeth3DPrints.push(element)
      }
    });
    return teeth3DPrints
  },
  get3DPrintsTreatments: (state, getters) => {
    var treatments = [];
    if (getters.get3DPrintsTeeth.length > 0) {
      const index = getters.getDentalWorks.findIndex(i => i.code == getters.get3DPrintsTeeth[0].treatment)
      if (getters.get3DPrintsTeeth.length > 0) {
        treatments.push({
          name: getters.getDentalWorks[index].name,
          color: getters.getDentalWorks[index].color,
          code: getters.getDentalWorks[index].code
        });
      }

      for (let index = 1; index < getters.get3DPrintsTeeth.length; index++) {
        const element = getters.get3DPrintsTeeth[index];
        const findedIndex = treatments.findIndex(i => i.code == element.treatment)

        if (findedIndex == -1) {
          const indexDiff = getters.getDentalWorks.findIndex(i => i.code == getters.get3DPrintsTeeth[index].treatment);
          treatments.push({
            name: getters.getDentalWorks[indexDiff].name,
            color: getters.getDentalWorks[indexDiff].color,
            code: getters.getDentalWorks[indexDiff].code
          });
        }
      }

    }

    return treatments;
  },
  getZirkonTeeth: state => {
    var teethZirkon = [];

    state.order.order_items.forEach(element => {
      if (element.material == 2) {
        teethZirkon.push(element)
      }
    });
    return teethZirkon
  },
  getZirkonTreatments: (state, getters) => {
    var treatments = [];
    if (getters.getZirkonTeeth.length > 0) {
      const index = getters.getDentalWorks.findIndex(i => i.code == getters.getZirkonTeeth[0].treatment)
      if (getters.getZirkonTeeth.length > 0) {
        treatments.push({
          name: getters.getDentalWorks[index].name,
          color: getters.getDentalWorks[index].color,
          code: getters.getDentalWorks[index].code
        });
      }

      for (let index = 1; index < getters.getZirkonTeeth.length; index++) {
        const element = getters.getZirkonTeeth[index];
        const findedIndex = treatments.findIndex(i => i.code == element.treatment)

        if (findedIndex == -1) {
          const indexDiff = getters.getDentalWorks.findIndex(i => i.code == getters.getZirkonTeeth[index].treatment);
          treatments.push({
            name: getters.getDentalWorks[indexDiff].name,
            color: getters.getDentalWorks[indexDiff].color,
            code: getters.getDentalWorks[indexDiff].code
          });
        }
      }
    }

    return treatments;
  },
  getImplantTeeth: state => {
    var teethImplant = [];

    state.order.order_items.forEach(element => {
      if (element.implant) {
        teethImplant.push(element)
      }
    });
    return teethImplant
  },
  getImplantTreatments: (state, getters) => {
    var treatments = [];
    if (getters.getImplantTeeth.length > 0) {
      const index = getters.getDentalWorks.findIndex(i => i.code == getters.getImplantTeeth[0].treatment)
      if (getters.getImplantTeeth.length > 0) {
        treatments.push({
          name: getters.getDentalWorks[index].name,
          color: getters.getDentalWorks[index].color,
          code: getters.getDentalWorks[index].code
        });
      }

      for (let index = 1; index < getters.getImplantTeeth.length; index++) {
        const element = getters.getImplantTeeth[index];
        const findedIndex = treatments.findIndex(i => i.code == element.treatment)

        if (findedIndex == -1) {
          const indexDiff = getters.getDentalWorks.findIndex(i => i.code == getters.getImplantTeeth[index].treatment);
          treatments.push({
            name: getters.getDentalWorks[indexDiff].name,
            color: getters.getDentalWorks[indexDiff].color,
            code: getters.getDentalWorks[indexDiff].code
          });
        }
      }
    }

    return treatments;
  },
  getImplantBrandModel: (state, getters) => {
    var implantList = []

    for (let index = 0; index < getters.getImplantTeeth.length; index++) {
      const element = getters.getImplantTeeth[index];

      if (index == 0) {
        implantList.push({
          brand: element.implant_brand,
          model: element.implant_model,
          teeth: [element.teeth]
        })
      } else {
        var findedIndex = implantList.findIndex(i => i.brand == element.implant_brand && i.model == element.implant_model)

        if (findedIndex != -1) {
          implantList[findedIndex].teeth.push(element.teeth)
        } else {
          implantList.push({
            brand: element.implant_brand,
            model: element.implant_model,
            teeth: [element.teeth]
          })
        }
      }
    }

    return implantList
  }
};

export default getters;
