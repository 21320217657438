const getters = {
  weightMultipier: state => {
    return state.weightMultipier;
  },
  selectedWeightMultipier: state => {
    return state.selectedWeightMultipier;
  },
  showedWeightMultipier: state => {
    return state.showedWeightMultipier;
  },
  basket: state => {
    return state.basket;
  },
  productGroups: state => {
    return state.productGroups;
  },
  getTotalResinCredit: state => {
    let total = 0;
    state.basket.forEach(e => {
      total += e.volume * e.quantity;
    });
    return total;
  },
  getTotalProductUnit: state => {
    let total = 0;
    state.basket.forEach(e => {
      total += parseInt(e.quantity);
    });
    return total;
  }
};

export default getters;
