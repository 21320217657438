export default {
  actionIcon: "StarIcon",
  highlightColor: "warning",
  data: [
    {
      index: 0,
      label: "Dashboard",
      url: "/",
      labelIcon: "HomeIcon",
      highlightAction: false
    },
    {
      index: 1,
      label: "Machines",
      url: "/machines",
      labelIcon: "FileIcon",
      highlightAction: false
    },
    {
      index: 2,
      label: "Print Logs",
      url: "/print_logs",
      labelIcon: "FileIcon",
      highlightAction: false
    },
    {
      index: 3,
      label: "Plates",
      url: "/plates",
      labelIcon: "FileIcon",
      highlightAction: false
    },
    {
      index: 4,
      label: "Design Market",
      url: "/designmarket",
      labelIcon: "FileIcon",
      highlightAction: false
    },
    {
      index: 5,
      label: "Orders",
      url: "/design_orders",
      labelIcon: "BriefcaseIcon",
      highlightAction: false
    }
  ]
};
