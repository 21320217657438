const mutations = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu

  TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE(state, value) {
    state.isVerticalNavMenuActive = value;
  },
  TOGGLE_REDUCE_BUTTON(state, val) {
    state.reduceButton = val;
  },
  UPDATE_MAIN_LAYOUT_TYPE(state, val) {
    state.mainLayoutType = val;
  },
  TOGGLE_VERTICAL_MENU_HIDE(state, val) {
    state.verticalNavMenuHide = val;
  },
  UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN(state, val) {
    state.verticalNavMenuItemsMin = val;
  },
  UPDATE_VERTICAL_NAV_MENU_WIDTH(state, width) {
    state.verticalNavMenuWidth = width;
  },

  // Navbar-Vertical

  ARRANGE_STARRED_PAGES_LIMITED(state, list) {
    const starredPagesMore = state.starredPages.slice(10);
    state.starredPages = list.concat(starredPagesMore);
  },
  ARRANGE_STARRED_PAGES_MORE(state, list) {
    let downToUp = false;
    let lastItemInStarredLimited = state.starredPages[10];
    const starredPagesLimited = state.starredPages.slice(0, 10);
    state.starredPages = starredPagesLimited.concat(list);

    state.starredPages.slice(0, 10).map(i => {
      if (list.indexOf(i) > -1) downToUp = true;
    });

    if (!downToUp) {
      state.starredPages.splice(10, 0, lastItemInStarredLimited);
    }
  },

  // ////////////////////////////////////////////
  // UI
  // ////////////////////////////////////////////

  TOGGLE_CONTENT_OVERLAY(state, val) {
    state.bodyOverlay = val;
  },
  UPDATE_PRIMARY_COLOR(state, val) {
    state.themePrimaryColor = val;
  },
  UPDATE_THEME(state, val) {
    state.theme = val;
  },
  UPDATE_WINDOW_WIDTH(state, width) {
    state.windowWidth = width;
  },
  UPDATE_WINDOW_SCROLL_Y(state, val) {
    state.scrollY = val;
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  // Updates user info in state and localstorage
  SET_USER_INFO(state, payload) {
    if (payload != null) {
      if (payload.name == "" || payload.name == null) {
        state.AppActiveUser.displayName = payload.company;
        state.userName = payload.name;
        localStorage.setItem("username", payload.company);
      } else {
        state.AppActiveUser.displayName = payload.name;
        localStorage.setItem("username", payload.name);
      }

      state.machineTypes = payload.machine_types;

      state.email = payload.email;
      localStorage.setItem("email", payload.email);

      state.designMarketVip = payload.design_market_vip;
      localStorage.setItem("design_market_vip", payload.design_market_vip);

      if (payload.design_market_vip) {
        state.AppActiveUser.photoURL = require("@/assets/images/portrait/small/avatar-vip.png");
      } else {
        state.AppActiveUser.photoURL = require("@/assets/images/portrait/small/avatar-s-11.png");
      }

      state.designMarketCredit = payload.design_market_credit;
      state.dentalCredit = payload.dental_platform_credit;
      state.noktaMarketCredit = payload.nokta_market_credit;

      localStorage.setItem("designCredit", payload.design_market_credit);
      localStorage.setItem("dentalCredit", payload.dental_platform_credit);
      localStorage.setItem("noktaMarketCredit", payload.nokta_market_credit);

      state.companyName = payload.company;
      localStorage.setItem("company", payload.company);
      state.AppActiveUser.userRole = payload.user_type;
      localStorage.setItem("usertype", payload.user_type);
      localStorage.setItem(
        "design_market_status",
        payload.design_market_status
      );
      localStorage.setItem(
        "dental_platform_status",
        payload.dental_platform_status
      );
      localStorage.setItem("nokta_market_status", payload.nokta_market_status);
      localStorage.setItem(
        "nokta_market_is_admin",
        payload.nokta_market_is_admin
      );

      if (payload.design_market_status) {
        state.designMarketPermission = true;
      } else {
        state.designMarketPermission = false;
      }
      if (payload.nokta_market_is_admin) {
        state.noktaAdminPermission = true;
      } else {
        state.noktaAdminPermission = false;
      }

      if (payload.nokta_market_status) {
        state.noktaMarketPermission = true;
      } else {
        state.noktaMarketPermission = false;
      }
      if (payload.dental_platform_status) {
        state.dentalPlatformPermission = true;
      } else {
        state.dentalPlatformPermission = false;
      }
      if (payload.dental_platform_laboratory) {
        state.providerPermission = true;
      } else {
        state.providerPermission = false;
      }
    }
  },

  // Auth
  SET_TOKEN(state, tokens) {
    state.token = tokens.token;
    state.refreshToken = tokens.reflesh_token_state;
  },
  clearLocalStorage(state) {
    state.token = "";
    state.refreshToken = "";
    state.designMarket.shopItems.splice(0, state.designMarket.shopItems.length);
    state.designMarket.shopItemsVisible.splice(
      0,
      state.designMarket.shopItemsVisible.length
    );
    state.noktaMarket.basket = [];
    state.noktaMarket.productGroups = [];

    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("username");
    localStorage.removeItem("company");
    localStorage.removeItem("usertype");
    localStorage.removeItem("design_market_vip");
    localStorage.removeItem("design_market_status");
    localStorage.removeItem("dental_platform_status");
    localStorage.removeItem("shop");
    localStorage.removeItem("orderId");
    localStorage.removeItem("dentalCredit");
    localStorage.removeItem("designCredit");
    localStorage.removeItem("email");
    localStorage.removeItem("machines");
    localStorage.removeItem("productCart");
    localStorage.removeItem("noktaBasket");
    localStorage.removeItem("noktaProductGroups");
    localStorage.removeItem("noktaMarketCredit");
    localStorage.removeItem("nokta_market_status");
    localStorage.removeItem("nokta_market_is_admin");
  },
  setPrintLogs(state, printLogs) {
    state.printLogs = printLogs;
  },
  UPDATE_DENTAL_CREDIT(state, credit) {
    state.dentalCredit = credit;
    localStorage.setItem("dentalCredit", credit);
  },
  UPDATE_DESIGN_MARKET_CREDIT(state, credit) {
    state.designMarketCredit = credit;
    localStorage.setItem("designCredit", credit);
  },
  SET_LOCALE(state, locale) {
    state.locale = locale;
  },
  INIT_LOCAL_DATA(state) {
    state.AppActiveUser.displayName = localStorage.getItem("username");
    state.AppActiveUser.userRole = localStorage.getItem("usertype");

    state.designMarketCredit = localStorage.getItem("designCredit");
    state.dentalCredit = localStorage.getItem("dentalCredit");

    state.email = localStorage.getItem("email");

    state.token = localStorage.getItem("token");
    state.refreshToken = localStorage.getItem("refreshToken");

    state.design_market_vip = localStorage.getItem("design_market_vip");
    if (state.design_market_vip == "true") {
      state.AppActiveUser.photoURL = require("@/assets/images/portrait/small/avatar-vip.png");
    } else {
      state.AppActiveUser.photoURL = require("@/assets/images/portrait/small/avatar-s-11.png");
    }

    state.company = localStorage.getItem("company");
    if (localStorage.getItem("design_market_status") == "true") {
      state.designMarketPermission = true;
    } else {
      state.designMarketPermission = false;
    }
    if (localStorage.getItem("dental_platform_status") == "true") {
      state.dentalPlatformPermission = true;
    } else {
      state.dentalPlatformPermission = false;
    }
    if (localStorage.getItem("nokta_market_status") == "true") {
      state.noktaMarketPermission = true;
    } else {
      state.noktaMarketPermission = false;
    }
    if (localStorage.getItem("nokta_market_is_admin") == "true") {
      state.noktaAdminPermission = true;
    } else {
      state.noktaAdminPermission = false;
    }

    if (localStorage.getItem("productCart")) {
      state.productCart = JSON.parse(localStorage.getItem("productCart"));
    }
  },
  ADD_PRODUCT_CART(state, product) {
    let index = state.productCart.findIndex(
      e => e.id == product.id && e.kg == product.kg
    );

    if (index != -1) {
      state.productCart[index].quantity += product.quantity;
    } else {
      state.productCart.push(product);
    }

    var sortedList = state.productCart.sort((a, b) =>
      a.title > b.title ? 1 : b.title > a.title ? -1 : 0
    );

    localStorage.setItem("productCart", JSON.stringify(sortedList));
  },
  REMOVE_PRODUCT_CART(state, product) {
    let index = state.productCart.findIndex(
      e => e.id == product.id && e.kg == product.kg
    );

    state.productCart.splice(index, 1);

    localStorage.setItem("productCart", JSON.stringify(state.productCart));
  },
  ADD_QUANTITY_PRODUCT_CART(state, product) {
    let index = state.productCart.findIndex(
      e => e.id == product.id && e.kg == product.kg
    );

    state.productCart[index].quantity += 1;

    localStorage.setItem("productCart", JSON.stringify(state.productCart));
  },
  REMOVE_QUANTITY_PRODUCT_CART(state, product) {
    let index = state.productCart.findIndex(
      e => e.id == product.id && e.kg == product.kg
    );

    state.productCart[index].quantity -= 1;

    if (state.productCart[index].quantity == 0) {
      state.productCart.splice(index, 1);
    }

    localStorage.setItem("productCart", JSON.stringify(state.productCart));
  },
  DECREASE_NOKTA_MARKET_CREDIT(state, credit) {
    state.noktaMarketCredit -= credit;
    localStorage.setItem("noktaMarketCredit", state.noktaMarketCredit);
  }
};

export default mutations;
