export default {
  changeWordToDo({ commit }, work) {
    commit("CHANGE_WORK_TO_DO", work);
  },
  changeImplant({ commit }, implant) {
    commit("CHANGE_IMPLANT", implant);
  },
  changeMateriel({ commit }, materiel) {
    commit("CHANGE_MATERIEL", materiel);
  },
  newWork({ commit }, work) {
    commit("NEW_WORK", work);
  },
  changeOrder({ commit }, order) {
    commit("CHANGE_ORDER", order);
  },
  removeDentalOrder({ commit }) {
    commit("REMOVE_DENTAL_ORDER");
  },
  changeTeethRelation({ commit }, dot){
    commit("CHANGE_TEETH_RELATION",dot)
  },
  changeTreatments({commit},treatments){
    commit("CHANGE_TREATMENTS",treatments)
  },
  cleanWork({commit}){
    commit("CLEAN_WORK")
  }
};