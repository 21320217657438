var state = {
  shopItems: [],
  shopItemsVisible: [],
  goldWeightMultipier: {
    gold14K: 9.4,
    gold18K: 11.4,
    gold21K: 12.4
  }
};

export default state;
