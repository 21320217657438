import state from "././moduleNoktaMarketState.js";
import mutations from "././moduleNoktaMarketMutations.js";
import actions from "././moduleNoktaMarketActions.js";
import getters from "././moduleNoktaMarketGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
