const actions = {
  // /////////////////////////////////////////////
  // COMPONENTS
  // /////////////////////////////////////////////

  // Vertical NavMenu
  updateVerticalNavMenuWidth({ commit }, width) {
    commit("UPDATE_VERTICAL_NAV_MENU_WIDTH", width);
  },

  // The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_LIMITED", list);
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit("ARRANGE_STARRED_PAGES_MORE", list);
  },

  // /////////////////////////////////////////////
  // UI
  // /////////////////////////////////////////////

  toggleContentOverlay({ commit }) {
    commit("TOGGLE_CONTENT_OVERLAY");
  },
  updateTheme({ commit }, val) {
    commit("UPDATE_THEME", val);
  },

  toggleVerticalMenuHide({ commit }, val) {
    commit("TOGGLE_VERTICAL_MENU_HIDE", val);
  },

  // /////////////////////////////////////////////
  // User/Account
  // /////////////////////////////////////////////

  updateUserInfo({ commit }, payload) {
    commit("UPDATE_USER_INFO", payload);
  },

  updateDesignMarketCredit({ commit }, credit) {
    commit("UPDATE_DESIGN_MARKET_CREDIT", credit);
  },
  updateDentalCredit({ commit }, credit) {
    commit("UPDATE_DENTAL_CREDIT", credit);
  },

  // /////////////////////////////////////////////
  // Auth
  // /////////////////////////////////////////////

  logout({ commit }) {
    commit("clearLocalStorage");
  },
  initAuth({ commit }) {
    let token = localStorage.getItem("token");
    let refreshToken = localStorage.getItem("refreshToken");

    if (token != null) {
      commit("SET_TOKEN", {
        token: token,
        reflesh_token_state: refreshToken
      });
    }
  },
  initLocalData({ commit }) {
    commit("INIT_LOCAL_DATA");
  },
  setUserInfo({ commit }, payload) {
    commit("SET_USER_INFO", payload);
  },
  setLocale({ commit }, locale) {
    commit("SET_LOCALE", locale);
  },
  addProductCart({ commit }, product) {
    commit("ADD_PRODUCT_CART", product);
  },
  removeProductCart({ commit }, product) {
    commit("REMOVE_PRODUCT_CART", product);
  },
  addQuantityProductCart({ commit }, product) {
    commit("ADD_QUANTITY_PRODUCT_CART", product);
  },
  removeQuantityProductCart({ commit }, product) {
    commit("REMOVE_QUANTITY_PRODUCT_CART", product);
  },
  decreaseNoktaMarketCredit({ commit }, credit) {
    commit("DECREASE_NOKTA_MARKET_CREDIT", credit);
  }
};

export default actions;
