var state = {
  weightMultipier: {
    resin: 0.0013,
    k14: 0.013,
    k18: 0.015,
    k21: 0.018
  },
  showedWeightMultipier: [
    {
      id: 1,
      weightType: "14K",
      value: 0.013
    },
    {
      id: 2,
      weightType: "18K",
      value: 0.015
    },
    {
      id: 3,
      weightType: "21K",
      value: 0.018
    }
  ],
  selectedWeightMultipier: {
    id: 0,
    weightType: "",
    value: 0
  },
  basket: [],
  productGroups: []
};

export default state;
