import state from "./moduleDesignMarketState.js";
import mutations from "./moduleDesignMarketMutations.js";
import actions from "./moduleDesignMarketActions.js";
import getters from "./moduleDesignMarketGetters.js";

export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
};
