export default {
  CHANGE_WORK_TO_DO(state, work) {
    state.workToDo = work;
  },
  CHANGE_IMPLANT(state, implant) {
    state.implant = implant;
  },
  CHANGE_MATERIEL(state, materiel) {
    state.materiel = materiel;
  },
  NEW_WORK(state, work) {
    let index = state.dentalWork.findIndex(i => i.teeth === work.teeth);
    if (index != -1) {
      state.dentalWork.splice(index, 1);
    } else {
      state.dentalWork.push(Object.assign({}, work));
    }
  },
  CLEAN_WORK(state) {
    state.dentalWork = []
  },
  CHANGE_ORDER(state, order) {
    if (order.credit != null) {
      state.order.credit = order.credit;
    }
    if (order.created != null) {
      state.order.created = order.created;
    }
    if (order.id != null) {
      state.order.id = order.id;
    }
    if (order.lab != null) {
      state.order.lab = order.lab;
    }
    if (order.lab_display != null) {
      state.order.lab_display = order.lab_display;
    }
    if (order.note != null) {
      state.order.note = order.note;
    }
    if (order.order_files != null) {
      state.order.order_files = order.order_files;
    }
    if (order.order_items != null) {
      state.order.order_items = order.order_items;
    }
    if (order.order_status != null) {
      state.order.order_status = order.order_status;
      state.order.order_status_display = order.order_status_display;
    }
    if (order.owner != null) {
      state.order.owner = order.owner;
    }
    if (order.patient != null) {
      state.order.patient = order.patient;
    }
    if (order.plate_height != null) {
      state.order.plate_height = order.plate_height;
      state.order.plate_width = order.plate_width;
    }
    if (order.machine_type != null) {
      state.order.machine_type = order.machine_type;
    }
    if (order.teeth_relation != null) {
      state.order.teeth_relation = order.teeth_relation;
    }
  },
  CHANGE_TREATMENTS(state, treatments){
    treatments.forEach(element => {
      const index = state.works.findIndex(i => i.code == element.name)
      state.works[index].credit= element.credit
      state.works[index].name= element.name_display
    });

  },
  REMOVE_DENTAL_ORDER(state) {
    state.order.credit = 0;
    state.order.id = null;
    state.order.lab = null;
    state.order.lab_display = "";
    state.order.note = "";
    state.order.order_files = [];
    state.order.order_items = [];
    state.order.order_status = "";
    state.order.order_status_display = "";
    state.order.owner = "";
    state.order.patient = "";
    state.order.plate_height = null;
    state.order.plate_width = null;
    state.order.teeth_relation = [];
    state.workToDo = {
      code: "",
      color: "",
      credit: 0,
      name: ""
    };
    state.implant = {
      implant: false,
      brand: "",
      model: ""
    };
    state.materiel = null,
      state.dentalWork = []
  },
  CHANGE_TEETH_RELATION(state, dot) {
    const index = state.order.teeth_relation.findIndex(i => i == dot)
    if (index != -1) {
      state.order.teeth_relation.splice(index, 1)
    }
    else {
      state.order.teeth_relation.push(dot)
    }
  }
};
