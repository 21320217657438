const getters = {
  getShopItems: state => {
    return state.shopItems;
  },
  getShopItemsLength: state => {
    return state.shopItems.length;
  },
  getShopItemsVisible: state => {
    return state.shopItemsVisible;
  },
  getGoldWeightMultipier: state => {
    return state.goldWeightMultipier;
  }
};

export default getters;
