var state = {
  workToDo: {
    code: "",
    color: "",
    credit: 0,
    name: ""
  },
  works: [
    {
      name: "Anatomik Kron",
      code: "ACR",
      color: "#80004D",
      credit: 1
    },
    {
      name: "Anatomik Alt Yapı",
      code: "ACO",
      color: "#D071A9",
      credit: 1
    },
    {
      name: "Kapşon Alt Yapı",
      code: "OCO",
      color: "#A926B9",
      credit: 1
    },
    {
      name: "Anatomik Ara Gövde",
      code: "APO",
      color: "#0A890A",
      credit: 1
    },
    {
      name: "Ara Gövde (Alt Yapı)",
      code: "RPO",
      color: "#00B705",
      credit: 1
    },
    {
      name: "Inlay Dolgu",
      code: "ION",
      color: "#1362F2",
      credit: 1
    },
    {
      name: "Alt Yapı Dolgu",
      code: "OIN",
      color: "#1250BE",
      credit: 1
    },
    {
      name: "Vener",
      code: "VEN",
      color: "#5027FC",
      credit: 1
    },
    {
      name: "Bar Ana Gövde",
      code: "BPI",
      color: "#A24807",
      credit: 1
    },
    {
      name: "Bar Ara Gövde",
      code: "BSE",
      color: "#E8731F",
      credit: 1
    },
    {
      name: "Sürgü",
      code: "ATT",
      color: "#022E3D",
      credit: 1
    },
    {
      name: "Teleskop (Basamak)",
      code: "TCR",
      color: "#B87471",
      credit: 1
    },
    {
      name: "All on X Ana Gövde",
      code: "AOC",
      color: "#95080F",
      credit: 1
    },
    {
      name: "All on X Ara Gövde",
      code: "AOP",
      color: "#D34D53",
      credit: 1
    },
    {
      name: "Hibrit Ana Gövde",
      code: "HCO",
      color: "#05555F",
      credit: 1
    },
    {
      name: "Hibrit Ara Gövde",
      code: "HPO",
      color: "#179BAD",
      credit: 1
    },
    {
      name: "Toronto Ana Gövde",
      code: "TCO",
      color: "#60521B",
      credit: 1
    },
    {
      name: "Toronto Ara Gövde",
      code: "TPO",
      color: "#9F9675",
      credit: 1
    }
  ],
  implant: {
    implant: false,
    brand: "",
    model: ""
  },
  materiel: null,
  dentalWork: [],
  order: {
    credit: 0,
    created: null,
    id: null,
    lab: null,
    lab_display: "",
    note: "",
    order_files: [],
    order_items: [],
    order_status: "",
    order_status_display: "",
    owner: "",
    patient: "",
    plate_height: null,
    plate_width: null,
    machine_type: null,
    teeth_relation: []
  }
};

export default state;
