export default {
  addItemInShop({ commit }, item) {
    commit("ADD_ITEM_IN_SHOP", item);
  },
  addItemInShopVisible({ commit }, item) {
    commit("ADD_ITEM_IN_SHOP_VISIBLE", item);
  },
  removeItemInShop({ commit }, item) {
    commit("REMOVE_ITEM_IN_SHOP", item);
  },
  clearItemShop({ commit }) {
    commit("CLEAR_ITEM_SHOP");
  },
  clearItemShopInvisible({ commit }) {
    commit("CLEAR_ITEM_SHOP_INVISIBLE");
  },
  firstAddedShopList({ commit }, list) {
    commit("FIRST_ADDED_SHOP_LIST", list);
  },
  updateItemQuantity({ commit }, payload) {
    commit("UPDATE_ITEM_QUANTITY", payload);
  },
  updateItemQuantitySameItem({ commit }, payload) {
    commit("UPDATE_ITEM_QUANTITY_SAME_ITEM", payload);
  },
  NEWupdateItemQuantity({ commit }, payload) {
    commit("NEW_UPDATE_ITEM_QUANTITY", payload);
  }
};
