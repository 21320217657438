import Vue from "vue";
import App from "./App.vue";
import VueLazyload from "vue-lazyload";

Vue.use(VueLazyload);

// import VueTagManager from "vue-tag-manager";

import VueMask from "v-mask";
Vue.use(VueMask);

// Tag Manager
// Vue.use(VueTagManager, {
//   gtmId: "GTM-WB97B4Z"
// });

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// Sentry Error Monitoring
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";

Sentry.init({
  // Master
  // dsn: "https://c303f1c938ae4ea29a15363231d7f670@sentry.io/5180125",
  // Dev
  dsn:
    "https://79421be4cb8144759ed75a397e2c0144@o369421.ingest.sentry.io/5196307",
  integrations: [
    new Integrations.Vue({
      Vue,
      attachProps: true,
      logErrors: true
    })
  ]
});

//Yandex Metrica
// import VueYandexMetrika from "vue-yandex-metrika";

// Vue.use(VueYandexMetrika, {
//   id: 64619863,
//   router: router
// });

//HOTJAR
// import Hotjar from "vue-hotjar";

// Vue.use(Hotjar, {
//   id: "1839259",
//   isProduction: true,
//   snippetVersion: 6
// });

// Theme Configurations
import "../themeConfig.js";

// Globally Registered Components
import "./globalComponents.js";

// Styles: SCSS
import "./assets/scss/main.scss";

// Tailwind
import "@/assets/css/main.css";

// Vue Router
import router from "./router";

// Vuex Store
import store from "./store/store";

// Vuejs - Vue wrapper for hammerjs
import { VueHammer } from "vue2-hammer";
Vue.use(VueHammer);

// PrismJS
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";

// Feather font icon
require("./assets/css/iconfont.css");

//Axios
import axios from "axios";

import i18n from "./i18n";

// Vue select css
// Note: In latest version you have to add it separately
// import 'vue-select/dist/vue-select.css';

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  axios,
  i18n,
  render: h => h(App)
}).$mount("#app");
