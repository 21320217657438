import navbarSearchAndPinList from "@/layouts/components/navbar/navbarSearchAndPinList";
import themeConfig from "@/../themeConfig.js";
import colors from "@/../themeConfig.js";

// /////////////////////////////////////////////
// Variables
// /////////////////////////////////////////////

const userDefaults = {
  uid: 0, // From Auth
  displayName: "", // From Auth
  photoURL: require("@/assets/images/portrait/small/avatar-s-11.png"),
  status: "online",
  userRole: ""
};

// /////////////////////////////////////////////
// State
// /////////////////////////////////////////////

const state = {
  AppActiveUser: userDefaults,
  bodyOverlay: false,
  isVerticalNavMenuActive: true,
  mainLayoutType: themeConfig.mainLayoutType || "vertical",
  navbarSearchAndPinList: navbarSearchAndPinList,
  reduceButton: themeConfig.sidebarCollapsed,
  verticalNavMenuWidth: "default",
  verticalNavMenuItemsMin: false,
  verticalNavMenuHide: false,
  scrollY: 0,
  starredPages: navbarSearchAndPinList.data.filter(
    page => page.highlightAction
  ),
  theme: themeConfig.theme || "light",
  themePrimaryColor: colors.primary,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar & navbar component
  windowWidth: null,

  //Auth
  token: "",
  refreshToken: "",
  client_id: "TrdKNHoMKLXtcqXZngJrVjWxAtiaHqnoWCY1m25z",
  client_secret:
    "nKvnbayU6XuLrAQ1cEtw8lpqQ7SBjNp6RFwRYxWLpYZ7gnEcQr4wbumytOh0K2YK3eFYFXVVEejdtf8POQVc7mm9n9eXJ35oZljihsrYlhsAmFSz9JCPDv1753DutopU",
  printLogs: [],
  companyName: "",
  userName: "",
  userType: "",
  email: "",
  locale: "",
  designMarketPermission: null,
  dentalPlatformPermission: null,
  providerPermission: null,
  noktaAdminPermission: null,
  noktaMarketPermission: null,
  designMarketCredit: 0,
  dentalCredit: 0,
  noktaMarketCredit: 0,
  machineTypes: [],
  dentalVersionActive: false,
  productCart: [],
  version: "2.0.0"
};

export default state;
