// added so later we can keep breakpoint in sync automatically using this config file
// import tailwindConfig from "../../tailwind.config.js"

const getters = {
  //CREDITS
  getDesignMarketCredit: state => {
    return state.designMarketCredit;
  },
  getDentalCredit: state => {
    return state.dentalCredit;
  },
  getNoktaMarketCredit: state => {
    return state.noktaMarketCredit;
  },
  getDesignMarketPermission: state => {
    return state.designMarketPermission;
  },
  getDentalPermission: state => {
    return state.dentalPlatformPermission;
  },
  getNoktaMarketPermission: state => {
    return state.noktaMarketPermission;
  },
  getMachineTypes: state => {
    return state.machineTypes;
  },
  getLocale: state => {
    return state.locale;
  },
  getDentalVersionActive: state => {
    return state.dentalVersionActive;
  },

  getProductCart: state => {
    return state.productCart;
  },
  getProductCartLength: state => {
    return state.productCart.length;
  },

  //Auth
  isAuthenticated: state => {
    return state.token !== "";
  },

  // COMPONENT
  // vx-autosuggest
  // starredPages: state => state.navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  windowBreakPoint: state => {
    // This should be same as tailwind. So, it stays in sync with tailwind utility classes
    if (state.windowWidth >= 1200) return "xl";
    else if (state.windowWidth >= 992) return "lg";
    else if (state.windowWidth >= 768) return "md";
    else if (state.windowWidth >= 576) return "sm";
    else return "xs";
  }
};

export default getters;
