import Vue from "vue";
import Router from "vue-router";
import store from "./store/store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    };
  },
  routes: [
    {
      // =============================================================================
      // MAIN LAYOUT ROUTES
      // =============================================================================
      path: "",
      component: () => import("./layouts/main/Main.vue"),
      children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
        {
          path: "/",
          name: "home",
          component: () => import("./views/Dashboard.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/machines",
          name: "machines",
          component: () => import("./views/Machines.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Machines",
                active: true
              }
            ],
            pageTitle: "Machines",
            rule: "editor"
          }
        },
        {
          path: "/print_logs",
          name: "print_logs",
          component: () => import("./views/PrintLogs.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Print Logs",
                active: true
              }
            ],
            pageTitle: "Print Logs",
            rule: "editor"
          }
        },
        {
          path: "/plates",
          name: "plates",
          component: () => import("./views/designMarket/Plates.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Plates",
                active: true
              }
            ],
            pageTitle: "Plates",
            rule: "editor"
          }
        },
        {
          path: "/design_market",
          name: "design_market",
          component: () => import("./views/designMarket/DesignMarket.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/design_orders",
          name: "design_orders",
          component: () => import("./views/designMarket/Orders.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Orders",
                active: true
              }
            ],
            pageTitle: "Orders",
            rule: "editor"
          }
        },
        {
          path: "/order_detail",
          name: "order_detail",
          component: () => import("./views/designMarket/OrderDetail.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Orders",
                url: "/design_orders"
              },
              {
                title: "Order Details",
                active: true
              }
            ],
            pageTitle: "Order Details",
            rule: "editor"
          }
        },
        {
          path: "/design_credit",
          name: "design_credit",
          component: () => import("./views/designMarket/Credit.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Credit",
                active: true
              }
            ],
            pageTitle: "Credit",
            rule: "editor"
          }
        },
        {
          path: "/cart",
          name: "cart",
          component: () => import("./views/designMarket/Cart.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/shop_cart",
          name: "shop_cart",
          component: () => import("./views/shop/Cart.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/dental_orders",
          name: "dental_orders",
          component: () => import("./views/dental/Orders.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Orders",
                active: true
              }
            ],
            pageTitle: "Orders",
            rule: "editor"
          }
        },
        {
          path: "/dental_credit",
          name: "dental_credit",
          component: () => import("./views/dental/Credit.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Credit",
                active: true
              }
            ],
            pageTitle: "Credit",
            rule: "editor"
          }
        },
        {
          path: "/dental_order",
          name: "dental_order",

          component: () => import("./views/dental/NewOrder.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Orders",
                url: "/dental_orders"
              },
              {
                title: "Order Details",
                active: true
              }
            ],
            pageTitle: "Order Details",
            rule: "editor"
          }
        },
        {
          path: "/dental_file",
          name: "dental_file",
          component: () => import("./views/dental/FileUpload.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Orders",
                url: "/dental_orders"
              },
              {
                title: "File Upload",
                active: true
              }
            ],
            pageTitle: "File Upload",
            rule: "editor"
          }
        },
        {
          path: "/provider_orders",
          name: "provider_orders",
          component: () => import("./views/provider/Orders.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Orders",
                active: true
              }
            ],
            pageTitle: "Orders",
            rule: "editor"
          }
        },
        {
          path: "/provider_history",
          name: "provider_history",
          component: () => import("./views/provider/History.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "History",
                active: true
              }
            ],
            pageTitle: "History",
            rule: "editor"
          }
        },
        {
          path: "/provider_order_detail",
          name: "provider_order_detail",
          component: () => import("./views/provider/OrderDetail.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "Orders",
                url: "/provider_orders"
              },
              {
                title: "Order Details",
                active: true
              }
            ],
            pageTitle: "Order Details",
            rule: "editor"
          }
        },
        {
          path: "/provider_history_detail",
          name: "provider_history_detail",
          component: () => import("./views/provider/HistoryDetail.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          },
          meta: {
            breadcrumb: [
              {
                title: "Home",
                url: "/"
              },
              {
                title: "History",
                url: "/provider_history"
              },
              {
                title: "History Details",
                active: true
              }
            ],
            pageTitle: "History Details",
            rule: "editor"
          }
        },
        {
          path: "/cart_size",
          name: "cart_size",
          component: () => import("./views/designMarket/CartSize.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/user_profile",
          name: "user_profile",
          component: () => import("./views/UserProfile.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/products",
          name: "products",
          component: () => import("./views/shop/Products.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/shop_orders",
          name: "shop_orders",
          component: () => import("./views/shop/Orders.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/mail_order",
          name: "mail_order",
          component: () => import("./views/shop/MailOrder.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/shop_order",
          name: "shop_order",
          component: () => import("./views/shop/OrderDetail.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_products",
          name: "nokta_products",
          component: () => import("./views/noktaMarketAdmin/Products.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/unapproved_products",
          name: "unapproved_products",
          component: () =>
            import("./views/noktaMarketAdmin/UnapprovedProducts.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_admin_customer2",
          name: "nokta_admin_customer2",
          component: () =>
            import("./views/noktaMarketAdmin/customers/Customers.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_admin_customer/:id",
          name: "nokta_admin_customer_detail",
          component: () =>
            import("./views/noktaMarketAdmin/customers/CustomerDetail.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_admin_customer/:id/orders",
          name: "nokta_admin_customer_detail_orders",
          component: () =>
            import("./views/noktaMarketAdmin/customers/CustomerOrders.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_admin_customer/:id/credits",
          name: "nokta_admin_customer_detail_credits",
          component: () =>
            import("./views/noktaMarketAdmin/customers/CustomerCredits.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_admin_credits",
          name: "nokta_admin_credits",
          component: () => import("./views/noktaMarketAdmin/Credits.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_admin_orders",
          name: "nokta_admin_orders",
          component: () => import("./views/noktaMarketAdmin/Orders.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_market_old",
          name: "nokta_market",
          component: () => import("./views/noktaMarket/Store.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_credits",
          name: "nokta_credits",
          component: () => import("./views/noktaMarket/Credits.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_basket",
          name: "nokta_basket",
          component: () => import("./views/noktaMarket/Basket.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_orders",
          name: "nokta_orders",
          component: () => import("./views/noktaMarket/Orders.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        },
        {
          path: "/nokta_order_detail",
          name: "nokta_order_detail",
          component: () => import("./views/noktaMarket/OrderDetail.vue"),
          beforeEnter(to, from, next) {
            store.dispatch("initAuth");
            if (store.getters.isAuthenticated) {
              next();
            } else {
              next("/login");
            }
          }
        }
      ]
    },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
    {
      path: "",
      component: () => import("@/layouts/full-page/FullPage.vue"),
      children: [
        // =============================================================================
        // PAGES
        // =============================================================================
        {
          path: "/login",
          name: "page-login",
          component: () => import("@/views/pages/Login.vue")
        },
        {
          path: "/forgot_password",
          name: "forgot_password",
          component: () => import("@/views/pages/ForgotPassword.vue")
        },
        {
          path: "/reset_password",
          name: "reset_password",
          component: () => import("@/views/pages/ResetPassword.vue")
        },
        {
          path: "/error_404",
          name: "page_error_404",
          component: () => import("@/views/pages/Error404.vue")
        },
        {
          path: "/maintenance",
          name: "maintenance",
          component: () => import("@/views/pages/Maintenance.vue")
        },
        {
          path: "/register",
          name: "register",
          component: () => import("@/views/pages/Register.vue")
        },
        {
          path: "/email-activation",
          name: "email-activation",
          component: () => import("@/views/pages/MailActivate.vue")
        },
        {
          path: "/",
          name: "/",
          component: () => import("@/views/pages/NoktaMarket.vue")
        }
      ]
    },

    // Redirect to 404 page, if no match found
    {
      path: "*",
      redirect: "/error_404"
    }
  ]
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
