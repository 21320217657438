import Vue from "vue";
import Vuex from "vuex";
import createMutationsSharer from "vuex-shared-mutations";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import designMarket from "./designMarket/moduleDesignMarket.js";
import dental from "./dental/moduleDental.js";
import noktaMarket from "./noktaMarket/moduleNoktaMarket.js";

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    designMarket,
    dental,
    noktaMarket
  },
  strict: process.env.NODE_ENV !== "production",
  plugins: [
    createMutationsSharer({
      predicate: [
        "designMarket/ADD_ITEM_IN_SHOP",
        "designMarket/CLEAR_ITEM_SHOP",
        "designMarket/REMOVE_ITEM_IN_SHOP",
        "designMarket/UPDATE_ITEM_QUANTITY",
        "designMarket/UPDATE_ITEM_QUANTITY_SAME_ITEM",
        "designMarket/NEW_UPDATE_ITEM_QUANTITY"
      ]
    })
  ]
});
